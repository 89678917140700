@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.cdnfonts.com/css/verdana");
@import url("https://fonts.cdnfonts.com/css/proxima-nova-condensed");
@import url("https://fonts.googleapis.com/css2?family=Krona+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Syne:wght@400..800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.slick-prev:before,
.slick-next:before {
  color: white;
  width: 40px;
}

.slick-prev,
.slick-next {
  width: 80px;
  height: 80px;
  z-index: 20;
}

html {
  scroll-behavior: smooth;
  font-family: 'Mona Sans';

}

@layer base {
  body {
    /* font-family: "Proxima Nova Condensed", sans-serif; */
    /* font-family: "Proxima Nova Extra Condensed", sans-serif; */
    /* font-family: "Proxima Nova", sans-serif; */
    /* font-family: "Public Sans", sans-serif; */
    /* font-family: 'Verdana', sans-serif; */
    /* font-family: "Montserrat", sans-serif; */
    /* font-family: "Lato", sans-serif; */
    /* font-family: "Roboto", sans-serif; */
    font-family: "Poppins", sans-serif;
    /* font-family: "Krona One", sans-serif; */
    font-optical-sizing: auto;
    font-style: light;
  }
}

body {
  background: #141619;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.025em;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: "Syne", sans-serif;
}

h1 {
  font-weight: 700;
  font-size: 32px;
  line-height: 2.25rem;
  letter-spacing: 0.1em;
}
h2 {
  text-align: center;
  padding: 40px 0; 
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
}
h3 {
  padding: 30px 0;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
}
h4 {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
}
h5 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}
h5 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

section {
  width: 90%;
  margin: 0 auto;
}
article {
  /* padding: 30px 0; */
  font-size: 14px;
  line-height: 20px;
}

.social-icon {
  font-size: 1.5rem;
  color: #fffafa;
}

@media screen and (min-width: 640px) {
  h1 {
    font-size: 36px;
    line-height: 2.5rem;
  }
}

@media screen and (min-width: 768px) {
  body {
    font-size: 16px;
    line-height: 24px;
  }
  
  section {
    width: 80%;
  }
  h1 {
    font-size: 60px;
    line-height: 1;
  }
  h2 {
    font-size: 30px;
    line-height: 36px;
    padding: 56px 0;
  }
  h3 {
    font-size: 24px;
    line-height: 32px;
  }
  h4 {
    font-size: 20px;
    line-height: 28px;
  }
  h5 {
    font-size: 18px;
    line-height: 28px;
  }
  .social-icon {
    font-size: 2rem;
  }
}
